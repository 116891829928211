<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="operationLogs">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('operationLog.content','内容')">
            <el-input v-model.trim="filter.content" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column label="平台" prop="platform" align="center"></el-table-column>
      <el-table-column :label="$l('operationLog.content','内容')" prop="content" min-width="300" align="center"></el-table-column>
      <el-table-column :label="$l('common.operator','操作人')" prop="name" width="200" align="center"></el-table-column>
      <el-table-column
        :label="$l('common.operatingTime','操作时间')"
        prop="createTime"
        width="150"
        align="center"></el-table-column>
    </vm-table>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        filter: {
          content: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
